.overlay {
  top: 50%;
  right: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
  z-index: 2;
}

.over-icon {
  width: 80px;
}

@media screen and (max-width: 650px) {
  .overlay {
    top: 75%;
  }

  .over-icon {
    width: 65px;
  }
}