
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "OmnesRegular", sans-serif;
  background-color: #e4e4e4;
}
