.footer {
  overflow: hidden;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: #E6E7EE;
  padding: 20px;
}

.footer-background {
  width: 100vw;
  position: absolute;
  z-index: 0;
  left: -20px;
  top: -28px;
}

.stretch {
  width: 100%;
}

.wrapper-background {
  background: linear-gradient(90deg, rgba(180, 75, 13, 1) 0%, rgba(194, 81, 17, 1) 100%);
  margin-top: -10px;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  z-index: 1;
}

.wrapper {
  width: 100%;
  position: relative;
}

.container2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 177px;
  margin-bottom: 50px;
  padding-inline: 4%;
  flex-wrap: wrap;
  row-gap: 40px;
}

.redes-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  gap: 10px;
  z-index: 1;
}

.encuentras-mas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  z-index: 1;
}

.encuentras-mas1 {
  font-family: "OmnesRegular";
  font-size: 33px;
  font-weight: bold;
  margin-bottom: -16px;
}

.encuentras-mas2 {
  color: #032454;
  font-family: "OmnesSemiBold";
  font-size: 50px;
}

.encuentras-mas3 {
  font-family: "OmnesSemiBold";
  font-size: 39px;
  letter-spacing: -1px;
  margin-left: -3px;
}

.encuentras-mas4 {
  font-family: "OmnesSemiBold";
  font-size: 52px;
  margin-top: -11px;

}

.title-redes {
  text-align: center;
  font-size: 25px;
  font-family: "OmnesRegular";
  font-weight: lighter;
  color: white;
}

.title-footer {
  text-align: center;
  font-size: 60px;
  font-family: "OmnesRegular";
  font-weight: lighter;
  margin-bottom: 30px;
  color: #004579;
}

.title-footer-plus {
  color: #eb6216;
  font-weight: 900;
}

.title-footer-bold {
  font-family: "OmnesBold";
}

.btn-click-footer {
  background-color: #004579;
  border-radius: 25px;
  width: 65%;
  color: white;
  font-size: 40px;
  border-style: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-family: "OmnesSemiBold";
  padding-block: 10px;
}

.btn-click-footer:hover {
  background-position: right center;
  color: #fff;
}

.derechos-footer {
  z-index: 1;
  text-decoration: none;
}

.derechos-footer h2 {
  font-size: 20px;
  font-weight: 300;
  color: white;
  text-decoration: none;
}

a.derechos-footer h2:hover,
a.derechos-footer h2:active {
  color: white;
  text-decoration: none;
}

.iconos-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

div.iconos-container img {
  width: 50px;
}

@media screen and (max-width: 650px) {
  .title-footer {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .container {
    margin-top: 30px;
  }

  .container2 {
    margin-top: 85px;
    justify-content: center;
  }

  .btn-click-footer {
    border-radius: 30px;
    height: 55px;
    width: 80%;
    font-size: 25px;
  }

  .footer-background {
    top: 0px;
  }

  .title-redes {
    font-size: 21px;
  }

  div.iconos-container img {
    width: 40px;
  }

  .encuentras-mas1 {
    font-size: 29px;
  }

  .encuentras-mas2 {
    font-size: 44px;
  }

  .encuentras-mas3 {
    font-size: 34px;
  }

  .encuentras-mas4 {
    font-size: 46px;
  }
}