.header-container {
  width: 100%;
  height: 105px;
  padding: 0px 50px 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../resources/iconos/pattern.svg");
  background-size: 56%;
  position: relative;
  box-shadow: 3px 3px 16px -10px rgba(0, 0, 0, 0.63);
}

.hidden {
  visibility: hidden;
}

.header-buttons {
  display: flex;
  gap: 15px;
}

.CC-fulljobs {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: white;
  padding: 20px;
  flex-direction: column;
}

.fulljobs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-block: 20px;
}

.empty-burger {
  width: 100px;
}

.fulljobs-title {
  color: #003457;
  font-family: "OmnesRegular";
  font-size: 38px;
  margin-right: auto;
}

.fulljobs-title2 {
  color: #003457;
  font-family: "OmnesRegular";
  font-size: 38px;
  text-align: center;
  margin-bottom: 30px;
}

.fulljobs-buscador-container {
  margin-bottom: 50px;
  margin-inline: auto;
}

.fulljobs-buscador {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 5px;
  margin-inline: auto;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.fulljobs-buscador-input {
  border-radius: 30px;
  border: 1px solid darkgray;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 17px;
}

.search-input {
  font-family: 'OmnesRegular';
  font-size: 20px;
  border: none;
  outline: none;
}

.search-input:active {
  border: none;
}

.search-button,
.signup-button {
  color: white;
  background: #EB6215;
  border: none;
  border-radius: 36px;
  padding: 13px;
  width: max-content;
  font-size: 20px;
  font-family: "OmnesRegular";
  cursor: pointer;
}

.website-button {
  color: white;
  background: #003457;
  border: none;
  border-radius: 36px;
  padding: 13px;
  width: max-content;
  font-size: 20px;
  font-family: "OmnesRegular";
  cursor: pointer;
}

.atento-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.atento-logo-vacantes {
  display: flex;
  align-items: center;
}

.css-1s2u09g-control {
  border-color: #e4e4e4 !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

h5 {
  margin-top: 0;
}

.fulljobs-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 28vw;
  height: 30vw;
  min-height: 30vw;
  background-color: #F5F5F5;
  border-radius: 15px;
  /* box-shadow: 3px 3px 16px -10px rgba(0, 0, 0, 0.63); */
}

.fulljobs-image {
  max-height: 40%;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 15px 15px 0px 0px;
}

.fj-img-card {
  width: 100%;
  /* max-height: 100%; */
}

.fj-video-card {
  width: 100%;
  border-radius: 15px;
}

.video-container {
  height: 152px;
}

.text-card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
}

.title-card-fj {
  color: #4e4e4e;
  font-size: 18px;
  margin-block: 10px;
}

.city-card-fj {
  color: #adadad;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  margin-top: 2px;
}

.type-card-fj {
  color: #0b26f0;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  margin-top: 2px;
}

.btn-card-fj {
  background-color: #003457;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: 0.5s;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  width: 75%;
  padding: 10px;
  margin-block: 10px;
}

.fj-card-footer {
  width: 100%;
  text-align: center;
}

.jobs-header h3 {
  font-size: 20px;
}

.jobs-header h2 {
  font-size: 28px;
}

.css-14el2xx-placeholder {
  font-size: 20px !important;
}

.jobs-header img {
  width: 130px;
}

.centered {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media screen and (max-width: 765px) {
  .fulljobs-buscador-container {
    width: min-content;
  }

  .fulljobs-title,
  .fulljobs-buscador-input {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 650px) {
  .header-container {
    padding: 15px 10px;
    background-size: 80%;
    height: unset;
  }

  .header-buttons {
    gap: 5px;
  }

  .signup-button,
  .website-button {
    font-size: 14px;
  }

  .search-button {
    font-size: 18px;
  }

  .search-input {
    font-size: 18px;
  }

  .video-container {
    height: 125px;
  }

  .css-6j8wv5-Input {
    font-size: 15px;
  }

  .css-14el2xx-placeholder {
    font-size: 22px;
  }

  .css-qc6sy-singleValue {
    font-size: 18px;
  }

  .css-1pndypt-Input {
    font-size: 18px !important;
  }

  .css-b62m3t-container {
    width: 320px;
  }

  .fulljobs-card {
    width: 320px;
    height: 240px;
  }

  .text-card-container {
    padding: 12px;
    padding-top: 4px;
    height: 80px;
    margin: 0;
  }

  .title-card-fj {
    font-size: 17px;
    margin-top: 2px;
  }

  .city-card-fj {
    font-size: 13px;
    margin-top: 5px;
  }

  .type-card-fj {
    font-size: 13px;
    margin-top: 5px;
  }

  .btn-card-fj {
    font-size: 14px;
    width: 268px;
    height: 35px;
  }

  .logo-jobs {
    width: 100%;
  }

  .jobs-header img {
    width: 100px;
  }

  .jobs-header h3 {
    font-size: 16px;
  }

  .jobs-header h2 {
    font-size: 20px;
  }

  .css-14el2xx-placeholder {
    font-size: 15px !important;
  }
}